import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Calling a gRPC service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "calling-a-grpc-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#calling-a-grpc-service",
        "aria-label": "calling a grpc service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calling a gRPC service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#exception-propagation"
        }}>{`Exception propagation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
  
    <p>{`Let's assume we have the following `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{` service definition, served at `}<inlineCode parentName="p">{`http://127.0.0.1:8080/`}</inlineCode>{`, just like
what we used in `}<a parentName="p" {...{
        "href": "/docs/server-grpc"
      }}>{`Running a gRPC service`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf"
      }}>{`syntax = "proto3";

package grpc.hello;

option java_package = "com.example.grpc.hello";

service HelloService {
  rpc Hello (HelloRequest) returns (HelloReply) {}
}

message HelloRequest {
  string name = 1;
}

message HelloReply {
  string message = 1;
}
`}</code></pre>
    <p>{`Making a call starts from creating a client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.grpc.GrpcClients;

HelloServiceBlockingStub helloService = GrpcClients.newClient(
        "gproto+http://127.0.0.1:8080/",
        HelloServiceBlockingStub.class); // or HelloServiceFutureStub.class or HelloServiceStub.class

HelloRequest request = HelloRequest.newBuilder().setName("Armerian World").build();
HelloReply reply = helloService.hello(request);
assert reply.getMessage().equals("Hello, Armerian World!");
`}</code></pre>
    <p>{`Note that we added the serialization format of the call using the `}<inlineCode parentName="p">{`+`}</inlineCode>{` operator in the scheme part of the URI.
Because we are calling a `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{` server, we can choose: `}<inlineCode parentName="p">{`gproto`}</inlineCode>{` or `}<inlineCode parentName="p">{`gjson`}</inlineCode>{`. If you are using `}<a parentName="p" {...{
        "href": "https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-WEB.md"
      }}>{`gRPC-Web`}</a>{`,
you can use `}<inlineCode parentName="p">{`gproto-web`}</inlineCode>{`, `}<inlineCode parentName="p">{`gproto-web-text`}</inlineCode>{` or `}<inlineCode parentName="p">{`gjson-web`}</inlineCode>{`.`}</p>
    <Tip mdxType="Tip">
      <p>{`If a serialization format is not specified, `}<inlineCode parentName="p">{`gproto`}</inlineCode>{` will be used by default.`}</p>
    </Tip>
    <p>{`Since we specified `}<inlineCode parentName="p">{`HelloServiceBlockingStub.class`}</inlineCode>{` as the client type, `}<inlineCode parentName="p">{`Clients.newClient()`}</inlineCode>{` will return a
synchronous client implementation.  If we specified `}<inlineCode parentName="p">{`HelloServiceFutureStub`}</inlineCode>{`, the calling code would have
looked like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.google.common.util.concurrent.Futures;
import com.google.common.util.concurrent.ListenableFuture;
import com.google.common.util.concurrent.MoreExecutors;
import com.linecorp.armeria.client.grpc.GrpcClients;
import java.util.concurrent.ForkJoinPool;

HelloServiceFutureStub helloService = GrpcClients.newClient(
        "gproto+http://127.0.0.1:8080/",
        HelloServiceFutureStub.class);

HelloRequest request = HelloRequest.newBuilder().setName("Armerian World").build();
ListenableFuture<HelloReply> future = helloService.hello(request);

Futures.addCallback(future, new FutureCallback<HelloReply>() {
    @Override
    public void onSuccess(HelloReply result) {
        assert result.getMessage().equals("Hello, Armerian World!");
    }

    @Override
    public void onFailure(Throwable t) {
        t.printStackTrace();
    }
}, MoreExecutors.directExecutor());

// You can also wait until the call is finished.
HelloReply reply = future.get();
`}</code></pre>
    <p>{`The asynchronous stub uses Guava's `}<a parentName="p" {...{
        "href": "https://google.github.io/guava/releases/21.0/api/docs/com/google/common/util/concurrent/ListenableFuture.html"
      }}>{`ListenableFuture`}</a>{` and can be operated on using methods on `}<a parentName="p" {...{
        "href": "https://google.github.io/guava/releases/21.0/api/docs/com/google/common/util/concurrent/Futures.html"
      }}>{`Futures`}</a>{`. The
`}<a parentName="p" {...{
        "href": "https://github.com/spotify/futures-extra"
      }}>{`futures-extra`}</a>{` library is very convenient for working with `}<a parentName="p" {...{
        "href": "https://google.github.io/guava/releases/21.0/api/docs/com/google/common/util/concurrent/ListenableFuture.html"
      }}>{`ListenableFuture`}</a>{` in Java 8, including the ability
to convert it to `}<a parentName="p" {...{
        "href": "https://docs.oracle.com/javase/10/docs/api/java/util/concurrent/CompletableFuture.html"
      }}>{`CompletableFuture`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{` also natively supports streaming RPC. If our service definition included this method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf"
      }}>{`service HelloService {
  rpc ManyHellos (stream HelloRequest) returns (stream HelloReply) {}
}
`}</code></pre>
    <p>{`You can also use the builder pattern for client construction:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.grpc.GrpcSerializationFormats;

HelloServiceBlockingStub helloService =
    GrpcClients.builder("http://127.0.0.1:8080/")
           .serializationFormat(GrpcSerializationFormats.PROTO)
           .responseTimeoutMillis(10000)
           .decorator(LoggingClient.newDecorator())
           .build(HelloServiceBlockingStub.class); // or HelloServiceFutureStub.class
                                                   // or HelloServiceStub.class

HelloRequest request = HelloRequest.newBuilder().setName("Armerian World").build();
HelloReply reply = helloService.hello(request);
assert reply.getMessage().equals("Hello, Armerian World!");
`}</code></pre>
    <p>{`As you might have noticed already, we decorated the client using `}<a parentName="p" {...{
        "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
      }}>{`type://LoggingClient`}</a>{`, which logs all
requests and responses. You might be interested in decorating a client using other decorators, for example
to gather metrics. Please also refer to `}<a parentName="p" {...{
        "href": "type://ClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html"
      }}>{`type://ClientBuilder`}</a>{` for more configuration options.`}</p>
    <h2 {...{
      "id": "exception-propagation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#exception-propagation",
        "aria-label": "exception propagation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exception propagation`}</h2>
    <p>{`If you have enabled `}<inlineCode parentName="p">{`Flags.verboseResponses()`}</inlineCode>{` in the server being accessed by specifying
`}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.verboseResponses=true`}</inlineCode>{` system property, then any exception during processing
in the server will be returned to the client as a `}<a parentName="p" {...{
        "href": "type://StatusCauseException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/StatusCauseException.html"
      }}>{`type://StatusCauseException`}</a>{` attached to the normal gRPC
`}<inlineCode parentName="p">{`Status`}</inlineCode>{`. This can be used for programmatic access to the exception that happened in the server. In this
example, the server always fails with `}<inlineCode parentName="p">{`throw new IllegalStateException("Failed!");`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.grpc.GrpcClients;
import com.linecorp.armeria.common.grpc.StatusCauseException;

import io.grpc.StatusRuntimeException;

HelloServiceBlockingStub helloService = GrpcClients.newClient(
        "gproto+http://127.0.0.1:8080/",
        HelloServiceBlockingStub.class); // or HelloServiceFutureStub.class or HelloServiceStub.class

HelloRequest request = HelloRequest.newBuilder().setName("Armerian World").build();
try {
    HelloReply reply = helloService.hello(request);
} catch (StatusRuntimeException e) {
    if (e.getCause() instanceof StatusCauseException) {
        StatusCauseException cause = (StatusCauseException) e.getCause();
        // The name of the class of the exception and its message in the server can be accessed.
        assert cause.getOriginalClassName().equals("java.lang.IllegalStateException");
        assert cause.getOriginalMessage().equals("Failed!");

        // The exception's message is a combination of both the class name and original message.
        assert cause.getMessage().equals("java.lang.IllegalStateException: Failed!");

        // The exception's stack trace is that which occurred when the server threw the exception.
        cause.printStackTrace();

        // Logging frameworks, as used by e.g., LoggingClient, will print the stack trace if configured
        // to do so.

        // Now you know exactly where to look in the server to figure out what may have gone wrong.
    }
}
`}</code></pre>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/server-grpc"
        }}>{`Running a gRPC service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-decorator"
        }}>{`Decorating a client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-custom-http-headers"
        }}>{`Sending custom HTTP headers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced-kotlin#calling-a-grpc-kotlin-service"
        }}>{`Calling a gRPC kotlin service`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      